// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-developers-page-js": () => import("./../src/templates/developers-page.js" /* webpackChunkName: "component---src-templates-developers-page-js" */),
  "component---src-templates-faq-page-js": () => import("./../src/templates/faq-page.js" /* webpackChunkName: "component---src-templates-faq-page-js" */),
  "component---src-templates-get-started-page-js": () => import("./../src/templates/get-started-page.js" /* webpackChunkName: "component---src-templates-get-started-page-js" */),
  "component---src-templates-how-it-works-page-js": () => import("./../src/templates/how-it-works-page.js" /* webpackChunkName: "component---src-templates-how-it-works-page-js" */),
  "component---src-templates-mission-page-js": () => import("./../src/templates/mission-page.js" /* webpackChunkName: "component---src-templates-mission-page-js" */),
  "component---src-templates-partner-page-js": () => import("./../src/templates/partner-page.js" /* webpackChunkName: "component---src-templates-partner-page-js" */),
  "component---src-templates-news-article-js": () => import("./../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */),
  "component---src-templates-digest-article-js": () => import("./../src/templates/digest-article.js" /* webpackChunkName: "component---src-templates-digest-article-js" */),
  "component---src-templates-pricing-page-js": () => import("./../src/templates/pricing-page.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-savings-calculator-page-js": () => import("./../src/templates/savings-calculator-page.js" /* webpackChunkName: "component---src-templates-savings-calculator-page-js" */),
  "component---src-templates-terms-page-js": () => import("./../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */),
  "component---src-templates-why-page-js": () => import("./../src/templates/why-page.js" /* webpackChunkName: "component---src-templates-why-page-js" */),
  "component---src-templates-wp-digest-article-js": () => import("./../src/templates/wp-digest-article.js" /* webpackChunkName: "component---src-templates-wp-digest-article-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-examples-js": () => import("./../src/pages/contact/examples.js" /* webpackChunkName: "component---src-pages-contact-examples-js" */),
  "component---src-pages-contact-file-upload-js": () => import("./../src/pages/contact/file-upload.js" /* webpackChunkName: "component---src-pages-contact-file-upload-js" */),
  "component---src-pages-contact-index-js": () => import("./../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("./../src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-merchant-payment-processing-index-js": () => import("./../src/pages/merchant-payment-processing/index.js" /* webpackChunkName: "component---src-pages-merchant-payment-processing-index-js" */),
  "component---src-pages-news-index-js": () => import("./../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-payments-digest-index-js": () => import("./../src/pages/payments-digest/index.js" /* webpackChunkName: "component---src-pages-payments-digest-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

